.fadeInOnScroll {
  opacity: 0;
  will-change: transform;
  animation-delay: 0.2s;

  &--1 { animation-delay: 0.2s; }
  &--2 { animation-delay: 0.3s; }
  &--3 { animation-delay: 0.4s; }
  &--4 { animation-delay: 0.5s; }
  &--5 { animation-delay: 0.6s; }
  &--6 { animation-delay: 0.7s; }

  &.is-visible {
    animation-name: fadeInUp;
    animation-duration: 1s;
    //animation-delay: 0.6s;
    animation-fill-mode: both;
  }
}


.fadeIn {
    animation-name: fadeInUp;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-delay: 0.5s;

    &--delay {
        animation-delay: 0.7s;
    }

    &--nodelay {
        animation-delay: 0;
    }
}

.fadeInAlt {
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-delay: 0.25s;
}
