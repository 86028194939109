.ContentBlock {
    padding: 4.5em 0;

    p {
        margin: 1em 0;
    }

    .Button {
        margin-top: 0.5em;
    }

    &-image {
        box-shadow: 0 0 40px rgba($black, 0.33);
    }

    &--split {
        padding: 0;

        &-image {
            min-height: 75vw;
        }

        @media (min-width: 992px) {
            display: flex;

            &-text {
                border-right: 0.25rem solid $white;
                flex: 1;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                min-height: 600px;
            }
            &-image {
                border-left: 0.25rem solid $white;
                flex: 1;
                min-height: 600px;
            }
        }
    }
}
