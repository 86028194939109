.FullPageSlide {
	@include background-center-cover;
	position: relative;
	height: 100vh;
	display: flex;
	align-items: center;

	&-overlay {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		//background-color: rgba(0,0,0,1);
		background-image: radial-gradient(circle, rgba(0,0,0,0.4), rgba(0,0,0,0.8));
		//opacity: 0.6;
        z-index: 1;

        &-graphic {
            background-image: url('/assets/toolkit/images/tree.svg');
            z-index: 2;
            opacity: 1;
            background-size: 50%;
            background-position: 130% bottom;
            background-repeat: no-repeat;
            pointer-events: none;
            opacity: 1;
        }

        &-graphic2 {
            background-image: url('/assets/toolkit/images/branch.png');
            background-position: -10px bottom;
            background-size: 50%;
        }
	}

	&-content {
		padding: 5em 3.5em 4em 0;
		position: relative;
		z-index: 3;
		visibility: hidden;
		animation-duration: 1.1s;
		animation-delay: 0.5s;
		animation-fill-mode: both;
		transition: visibility 0.5s;
        visibility: visible;
		animation-name: fadeInUp;

        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        &-play {
            img {
                display: inline-block;
                width: 80px;
                transition: transform 0.25s;
            }

            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }

		@media (max-width: 767px) {
			padding-top: 5em;
		}

		h1 {
            margin-top: 1em;
			color: inherit;
            text-transform: uppercase;
		}

		p {
			@media (min-width: 768px) {
				margin: 1.5em 0;
				font-size: 1.1em;
			}
		}
	}

    &-more {
        position: absolute;
        bottom: 3em;
        width: 100%;
        z-index: 4;
        opacity: 0.8;
        display: flex;
        justify-content: center;
        animation: bounce 2s infinite;
    }

	&--v1 {
		color: $white;

		.FullPageSlide-content {
			width: 90%;

			@media (min-width: 768px) {
				width: 550px;
			}
		}
	}

	&--hasVideo {
		position: relative;
		background: black;
        overflow: hidden;

		.FullPageSlide-content {
			position: relative;
			z-index: 1;
		}

		video {
			position: absolute;
    	    top: 0;
    	    object-fit: cover;
    	    object-position: 50% 50%;
    	    left: 0;
    	    height: 100%;
    	    width: 100%;
		}
	}
}
