.Page {
    @media (max-width: 767px) {
        .container {
            padding: 0;
        }
    }

    &-header {
        h1 {
            margin: 0.25rem 0;
            @media (min-width: 768px) {
                font-size: 3em;
            }
        }
    }

    &-content {
        padding-top: 2em;

        @media (min-width: 992px) {
            width: 50%;
            float: left;
        }

        @media (min-width: 1100px) {
            &:first-child {
                width: 54%;
            }
            &:last-child {
                width: 46%;
            }
        }
    }
}
