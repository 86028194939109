.Button {
    display: inline-block;
    background-color: $brand-green-light;
    color: $white;
    padding: 0.5em 1em;
    font-weight: bold;
    transition: all 0.2s;

    &:hover {
        background-color: darken($brand-green-light, 10);
        color: $white;
    }

    &--dark {
        background-color: $brand-green;
    }

    &--invert {
        color: $brand-green-light;
        background-color: $white;
    }
}
