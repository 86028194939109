.slick-dots {
    bottom: 25px;
}

.slick-dotted.slick-slider {
    margin-bottom: 0;
}

.slick-dots li button:before {
    font-size: 4rem !important;
    color: rgba($white, 0.8);
    opacity: 0.5;
}

.slick-dots li.slick-active button:before {
    color: $white;
}
