.card {
    box-shadow: 0 3px 25px rgba($black, 0.1);
    border: none;
    border-radius: 0;

    &--horiz {
        img {
            filter: grayscale(80%);
            transition: filter 0.4s;
        }

        &:hover {
            img {
                filter: grayscale(0%);
            }
        }

        .card-title {
            margin-bottom: 1rem;
        }
    }
}
