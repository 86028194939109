.Home {
    @media (min-width: 768px) {
        p {
            //font-size: 1.625em;
            margin: 0 0 1rem 0;
        }

        .Button {
            font-size: 1.5em;
        }
    }
}
