.Nav {
    ul {
        @include list-nostyle;
        li {
            a {
                display: block;
                padding: 1em 1.75em;
                color: $white;
            }

            &.active, &:hover {
                a {
                    background: $brand-green;
                }
            }
        }
    }
}

.nav-wrap {
    position: fixed;
    right: 0;
    z-index: 1001;
    width: 320px;
    height: 100%;
    background: $brand-green-dark;
    padding: 5rem 0 0;
    font-size: 1.4em;
    transform: translate3d(320px,0,0);
    transition: transform 0.4s;
    transition-timing-function: cubic-bezier(0.7,0,0.3,1);
}

body.show-menu .nav-wrap {
    transition: transform 0.8s;
    transition-timing-function: cubic-bezier(0.7,0,0.3,1);
    transform: translate3d(0,0,0);
}

.nav-close {
    width: 1em;
    height: 1em;
    padding: 0.75em;
    position: absolute;
    left: 2em;
    top: 1.5em;
    overflow: hidden;
    text-indent: 1em;
    font-size: 0.75em;
    border: none;
    background: transparent;
    color: transparent;

    &:before, &:after {
        content: '';
        position: absolute;
        width: 3px;
        height: 100%;
        top: 0;
        left: 50%;
        background: $light-grey;
    }

    &:before {
        transform: rotate(45deg);
    }

    &:after {
        transform: rotate(-45deg);
    }
}
