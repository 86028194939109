.u-bg {
	&--green, &--greendark {
		background-color: $brand-green-light;
		color: $white;

		h1, h2, h3, h4, h5, h6 {
			color: $white;
		}
	}

	&--greendark {
		background-color: $brand-green;
	}
}

.u-bg--centercover {
	@include background-center-cover;
}
