.Tile {
    padding: 1em;
    transition: color 0.3s;

    &-inner {
        box-shadow: 0 8px 20px rgba($black, 0.1);
    }

    &-content {
        position: relative;

        &-text {
            display: flex;
            justify-content: space-between;
            padding: 1.5em;
            line-height: 1;
            background: $white;
            font-weight: bold;

            h4 {
                margin-bottom: 0;
            }
            i {
                font-size: 1.5em;
            }
        }
    }

    &-image {
        position: relative;
        @include background-center-cover;
        width: 100%;
        height: 60vw;
        filter: grayscale(100%);
        transition: filter 0.4s;

        @media (min-width: 1024px) {
            height: 32vw;
            max-height: 50vh;
        }

        &-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba($brand-orange, 0.6);
            opacity: 0;
            will-change: opacity;
            transition: opacity 0.3s;
        }

    }
}
