.TileRow {
    margin-left: -1em;
    margin-right: -1em;

    @media (min-width: 1024px) {
        display: flex;
    }

    .Tile {
        padding: 1em;
        flex: 1;
        transition: transform 0.3s;
        will-change: transform;

        &:hover {
            transform: scale(1.05);

            .Tile-image-overlay {
                opacity: 1;
            }

            .Tile-image {
                filter: grayscale(0%);
            }
        }
    }
}
