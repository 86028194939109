p {
    line-height: 1.4;
    font-size: 1.25em;
    margin: 1em 0;

    &.larger {
        font-size: 1.5em;
    }

    @media (min-width: 768px) {
        font-size: 1.5em;

        &.larger {
            font-size: 1.7em;
        }
    }

    & + p {
        margin-top: 1em;
    }
}
