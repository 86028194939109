.Funders {
    padding: 2em 0 4em 0;
    border-top: 1px solid $light-grey;

    &-logos {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 991px) {
            flex-wrap: wrap;
            justify-content: center;
        }

        img {
            max-width: 140px;
            max-height: 100px;
            filter: grayscale(100%);

            &:hover {
                filter: grayscale(0);
            }

            @media (max-width: 991px) {
                padding: 1em;
            }
        }
    }
}
