.Footer {
    position: relative;
    z-index: 100;
    background-color: $brand-green;
    color: $light-grey;
    padding: 2em 3em;
    font-size: 0.8em;

    p {
        margin: 0.5rem 0;
    }

    &-logo {
        display: flex;
        align-items: center;

        @media (max-width: 1023px) {
            flex-wrap: wrap;
        }

        img {
            max-height: 90px;
            max-width: 160px;
            margin: 1rem;

            @media (max-width: 1023px) {
                max-width: 60px;
                margin: 0.5rem;
            }
        }
    }
}
