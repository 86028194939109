/**
 * Toolkit styles
 */

/* Normalise */
@import "normalise/normalize";
@import "normalise/box-sizing";

/* Plugins */
@import "node_modules/bootstrap/scss/_functions.scss";
@import "node_modules/bootstrap/scss/_variables.scss";
@import "node_modules/bootstrap/scss/_mixins.scss";
@import "node_modules/bootstrap/scss/_grid.scss";
@import "node_modules/bootstrap/scss/_buttons.scss";
@import "node_modules/bootstrap/scss/_forms.scss";
@import "node_modules/bootstrap/scss/_card.scss";
@import "node_modules/bootstrap/scss/_type.scss";
@import "node_modules/bootstrap/scss/_utilities.scss";
@import "node_modules/bootstrap/scss/_reboot.scss";
$slick-font-path: '../fonts/';
$slick-loader-path: '../images/icons/';
@import 'node_modules/slick-carousel/slick/slick.scss';
@import 'node_modules/slick-carousel/slick/slick-theme.scss';
@import 'node_modules/magnific-popup/src/css/main.scss';


/* Settings */
@import "settings/colours";
@import "settings/typography";
@import "settings/magnific-popup";
@import "settings/slick-carousel";

/* Utilities */
@import "utilities/mixins";
@import "utilities/spacing";
@import "utilities/keyframes";
@import "utilities/fadeonscroll";
@import "utilities/hamburger";
@import "utilities/backgrounds";

/* Elements */
@import "elements/body";
@import "elements/headings";
@import "elements/links";
@import "elements/image";

/* Components */
@import "components/buttons";
@import "components/paragraph";
@import "components/Tile";
@import "components/Logo";
@import "components/card";
@import "components/Pagination";

/* Modules */
@import "modules/Home";
@import "modules/Explore";
@import "modules/Header";
@import "modules/Footer";
@import "modules/TileRow";
@import "modules/VideoCard";
@import "modules/Page";
@import "modules/FullPageSlide";
@import "modules/Nav";
@import "modules/Funders";
@import "modules/ContentBlock";

/* Overrides */
