.hamburger {
    position: relative;
    z-index: 1000;
    margin: 0;
    padding: 0;
    width: 2.5em;
    height: 2em;
    border: none;
    text-indent: 2.5em;
    font-size: 1.5em;
    color: transparent;
    background: transparent;

    &:hover {
        color: transparent;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        bottom: 0.5em;
        left: 0.5em;
        background: linear-gradient(
            to bottom,
            white, white 10%,
            transparent 10%, transparent 45%,
            white 45%, white 55%,
            transparent 55%, transparent 90%,
            white 90%, white 100%
          );
    }

}
