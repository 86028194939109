.VideoCard {
    position: relative;

    &-icon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: 7em;
        color: $faint-grey;
        text-shadow: 0 0 20px rgba(0, 0, 0, 0.74);
        background: rgba($black, 0.25);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: all 0.4s;

        img {
            width: 80px;
        }

        &:hover {
            background: rgba($black, 0.1);
            color: $white;
        }
    }
}
