.Pagination {
    position: relative;
    z-index: 1;
    border-top: 1px solid $light-grey;
    box-shadow: 0 0px 10px rgba($black, 0.25);

    &.fixed {
        position: fixed;
        width: 100%;
        bottom: 0;
        z-index: 1000;
    }

    &-arrow {
        background: $faint-grey;
        font-size: 1.5em;
        flex: 1;
        padding: 1.25rem 1rem;
        line-height: 1;
        display: flex;
        align-items: center;
        transition: all 0.3s;

        i {
            margin: 0 0.5rem;
        }

        &:first-child {
            border-right: 1px solid $light-grey;
        }

        &:hover {
            background-color: #f2f2f2;
        }
    }
}
