// Generic colours
$black: 			#000000;
$white:				#FFFFFF;
$grey:				#555555;
$dark-grey:		#333333;
$darker-grey:		#222222;
$mid-grey:		#B9B9B9;
$light-grey:	#E9E9E9;
$faint-grey: 	#F8F8F8;

$brand-orange:  #ed5508; // #ed5508; // #db7134
$brand-green:   #1a2a1b;
$brand-green-dark:   #121A13;
$brand-green-light:   #3d7040;
$brand-red:     #9A2033;
