.Header {
    //background: $black;
    padding: 2em 1em;
    //box-shadow: 0 3px 10px rgba($black, 0.5);
    z-index: 99 !important;
    color: $white;
    position: absolute;
    width: 100%;

    &-inner {
        background-image: url('/assets/toolkit/images/apple-logo.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }


    &-icon {
        width: 50px;
    }

    @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        //padding: 1.25em 1.75em;
    }

    @media (max-width: 767px) {
        .Logo, .Logo h1 {
            justify-content: center;
        }
    }

    .Nav {
        @media (max-width: 767px) {
            margin-top: 0.75em;

            ul {
                justify-content: center;
            }
        }

        ul {
            @include list-nostyle;
            display: flex;
            font-size: 1.5em;

            li {
                padding: 0 0.5em;

                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }

                a {
                    color: $white;
                    transition: color 0.25s;

                    &:hover {
                        color: $brand-orange;
                    }
                }
            }
        }
    }
}
