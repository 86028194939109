h1,h2,h3,h4,h5,h6 {
    font-family: $heading-font;
    font-weight: bold;
    margin-top: 1.5em;
    text-transform: uppercase;

    &:first-child {
        margin-top: 0;
    }
}

h1 {
    color: $brand-orange;
    font-family: $logo-font;
    font-weight: 800;
    font-size: 2.5em;
    margin-top: 0.5rem;
    letter-spacing: 2px;
    line-height: 1.1;

    @media (min-width: 768px) {
        font-size: 3.75em;
    }
}

h2 {
    @media (min-width: 768px) {
        font-size: 2.75em;
    }
}
